<template>
  <div style="min-width: 350px">
    <header>
      <div class="title">
        <span>登录</span>
        <router-link :to="{ path: 'zhucezh', query: { setid: 1231231 } }">
          <p>还没有账号？<a href="javascript:void(0);">去注册</a></p>
        </router-link>
      </div>
      <!-- 手机号码 验证码 登录键 -->
      <div class="shuru center">
        <div class="number">
          <span class="spanliss" @click="showPopup = true">
            +{{ AreacodeList }}
            <img
              class="arrowDown"
              src="../../assets/images/arrow-down.png"
            />
          </span>
          <div class="line"></div>
          <van-popup
            v-model="showPopup"
            position="bottom"
            :style="{ height: '100%', background: '#f6f6f6' }"
          >
            <van-nav-bar
            title="选择国家地区"
            left-text="返回"
            @click-left="showClass"
            />
            <div style="background: #fff; margin-top: 10px; overflow: hidden">
              <div
                @click="classChange(item)"
                v-for="(item, index) in Areacode"
                :key="index"
                class="areaDetail"
              >
                <span style="margin-left: 20px">{{ item.nameZh }}</span>
                <span style="float: right; margin-right: 20px"
                  >+{{ item.phonecode }}</span
                >
              </div>
            </div>
          </van-popup>
          <input
            v-model="phone"
            class="phone"
            type="text"
            placeholder="输入手机号"
          />
        </div>
        <div class="yanzheng">
          <div class="captcha">验证码</div>
          <div class="codeline"></div>
          <input v-model="code" type="text" placeholder="输入验证码" />
          <p v-show="show" @click="yanzheng()" class="getCode">获取验证码</p>
          <span
            v-show="!show"
            class="countStyle"
            >重新获取 ({{ count }}s)</span
          >
        </div>
      </div>
    </header>
    <div class="denglu center">
      <button @click="login()">登录</button>
      <div class="sjdl" @click="toPwdLoginPage">账号密码登录</div>
    </div>
    
    <!-- 已阅读协议 -->
    <div class="xieyi">
      <van-checkbox
        v-model="agreement"
        icon-size="18px"
        checked-color="#1872ed"
      ></van-checkbox>
      <span>
        已阅读并同意<a
          href="javascript:void(0);"
          @click="$router.push({ path: '/privacy' })"
          >《服务协议》</a
        >和<a
          href="javascript:void(0);"
          @click="$router.push({ path: '/agreement' })"
          >《隐私协议》</a
        >
      </span>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast,Navbar} from "vant";
import { mapGetters } from "vuex";
import { getOrgDomain } from '@/utils'

Vue.use(Toast);
export default {
  computed: {
    ...mapGetters({
      org_id: "user/getOrgId",
    }),
  },
  data() {
    return {
      AreacodeList: "86",
      Areacode: "",
      showPopup: false,
      phone: "",
      code: "",
      show: true,
      count: "",
      timer: null,
      agreement: true,
    };
  },
  created() {
    this.areaCodes();
  },
  methods: {
    showClass() {
      this.showPopup = false;
    },
    classChange(item) {
      this.AreacodeList = item.phonecode;
      this.showPopup = false;
      this.areaCodes();
    },
    goOff() {
      // this.$router.go(-1)
      const { redirectUrl } = this.$route.query;
      if (redirectUrl && redirectUrl.includes("zhibo")) {
        this.$router.go(-2);
      } else {
        this.$router.go(-1);
      }
    },
    // 登录
     login() {
      if (!this.agreement) {
        return Toast.fail("请先阅读并同意协议！");
      }
      if (this.phone === "" || this.code === "") {
        return Toast.fail("账号、验证码不能为空");
      }

      this.checkOrgIdIsChunzai((org_id) => {
        const data = {
          area: this.AreacodeList,
          phone: this.phone,
          code: this.code,
          org_id: org_id,
        };
        this.$api.post("/user/index/phoneLogin", data).then(async(res) => {
          if (res.data.code === 200) {
            localStorage.setItem("token", res.data.data.token);
            this.$store.commit("user/setFansId", res.data.data.fansId);
            this.$store.commit("user/setIScheckToken", true);
            localStorage.setItem("tengface_fans_id", res.data.data.fansId);
            Toast.success("登录成功");
            // this.$router.push({
            //   path: '/'
            // })
            this.toPage();
            this.getUserInfo()
          } else {
            Toast.fail("账号、验证码错误或为空");
          }
        });
      });
    },
    getUserInfo() {
      this.$api.post('/user/index/getUserInfo').then((res) => {
        if (res.data.code === 200) {
          this.$store.commit('user/setRemark',res.data.data.stu_note)
        } 
      })
    },
    checkOrgIdIsChunzai(callBack) {
      if (this.org_id) {
        callBack && callBack(this.org_id);
      } else {
        this.$api
          .post("/common/index/getOrgInfo", {
            // domain: window.location.host,
            // domain: 'wx-adia.shanhuketang.com'
            domain: getOrgDomain()
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.$store.commit("user/setOrgId", res.data.data.uid);
              localStorage.setItem("tengface_org_id", res.data.data.uid);
              callBack && callBack(res.data.data.uid);
            }
          })
          .catch(() => {
            callBack && callBack();
          });
      }
    },
    // 获取验证码
    yanzheng() {
      this.checkOrgIdIsChunzai((org_id) => {
        const data = {
          phone: this.phone,
          area: this.AreacodeList,
          org_id: org_id,
        };
        this.$api.post("https://twin-live.shktclass.com/sms/studentLoginSendSms", data).then((res) => {
          if (res.data.code === 200) {
            this.areaCodes();
            Toast.success("获取成功");
          }
          if (res.data.code === 200) {
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            Toast.fail("获取失败");
          }
        });
      });
    },
    toPwdLoginPage() {
      const { redirectUrl } = this.$route.query;
      this.$router.replace({ path: "/zhanghaomimadl", query: { redirectUrl } });
    },
    // 地区码获取
    areaCodes() {
      this.$api.post("/common/index/areaCodes").then((res) => {
        if (res.data.code === 200) {
          this.Areacode = res.data.data;
        }
      });
    },
    toPage() {
      const { redirectUrl } = this.$route.query;
      if (redirectUrl) {
        // window.location.href = redirectUrl
        window.location.replace(redirectUrl);
      } else {
        this.$router.replace({
          path: "/",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
body {
  width: 100%;
  height: 768px;
  font-family: PingFangSC, PingFangSC-Semibold;
}
.center {
  margin: 0 auto;
}
input {
  border: 0;
  font-size: 14px;
  color: #303133;
  font-weight: 400;
}
input:-webkit-autofill {
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
}
a {
  text-decoration: none;
}
input,
textarea {
  -webkit-user-select: auto;
  caret-color: #1872ed;
}
/* 头部 */
.sjdl {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #1872ed;
  text-align: center;
}
.wjmm {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  float: right;
  color: #808695;
  margin-right: 30px;
}
header {
  height: 230px;
  background-image: linear-gradient(160deg, #e0edff 10%, #fff 70%);
  background-size: 100% 100%;
}
header .fanhui {
  height: 44px;
}
header .fanhui a {
  display: block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/ico_fanhui.png) no-repeat center;
  position: relative;
  top: 30px;
  left: 16px;
}
header .title {
  padding-top: 35px;
  margin-left: 30px;
}
header .title span {
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  color: #303133;
}
header .title p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #888888;
}
header .title p a {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #1872ed;
}
.shuru {
  width: 84%;
  margin-top: 24px;
}
.shuru .number {
  height: 44px;
  background: #f6f6f6;
  border-radius: 22px;
  position: relative;
}
.spanliss {
  display: block;
  float: left;
  margin-left: 6px;
  width: 46px;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #101010;
  .arrowLeft{
    position: absolute; 
    top: 17px; 
    left: 45px;
    font-size:12px
  }
  .arrowDown{
    position: absolute;
    top: 16px;
    left: 45px;
    height:12px;
    width:12px
  }
}
.line{
  position: absolute;
  left: 63px;
  top: 17px;
  height: 20px;
  width: 1px;
  height: 10px;
  background: #999999;
}
.password input {
  float: left;
  height: 44px;
  background: #f6f6f6;
  width: 380px !important;
}
.shuru input {
  float: left;
  height: 44px;
  background: #f6f6f6 !important;
  padding: 0 20px;
  border-radius: 25px;
}

.shuru .yanzheng {
  margin-top: 20px;
  height: 44px;
  background: #f6f6f6;
  border-radius: 22px;
  display: flex;
  position: relative;
  .captcha{
    font-size: 14px;
    font-weight: 400;
    color: #101010;
    padding-top: 12px;
    padding-left: 14px;
  }
  .codeline{
    position: absolute;
    left: 63px;
    top: 17px;
    height: 10px;
    width: 1px;
    background: #999;
  }
  .getCode{
    position:absolute;
    right: 16px;
  }
  .countStyle{
    position: absolute;
    right: 16px;
    margin-left: 26px;
    line-height: 46px;
    font-size: 12px;
    color: #888888;
  }
}
.shuru .yanzheng input {
  width: 41%;
  padding-left: 16px;
}
.shuru .yanzheng p {
  display: block;
  float: right;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #1872ed;
}
.denglu {
  width: 84%;
}
.denglu button {
  margin-bottom: 20px;
  display: block;
  width: 100%;
  margin-top: 25px;
  height: 44px;
  background: linear-gradient(98deg, #73aaff 0%, #0053ff 100%);
  border-radius: 22px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 44px;
  border: none;
}
.xieyi {
  position: fixed;
  bottom: 40px;
  left: 0;
  height: 20px;
  height: 20px;
  line-height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.xieyi span {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #303133;
  margin-left: 10px;
}
.xieyi span a {
  color: #1872ed;
}
.areaDetail{
  padding: 10px 0;
  border-bottom: 1px solid #f6f6f6
}
::v-deep .van-nav-bar__text{
  color: #323233;
}
::v-deep .van-icon{
  font-size: 12px;
}
::v-deep .van-checkbox__icon .van-icon{
  font-size: 13px;
}
</style>
