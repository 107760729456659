<template>
    <div class="liveBroadcast">
        <!-- 第一部分 -->
        <div class="headerBox">
            <div class="diyi">
                <img v-if="!VODData" class="courseCover" :src="cover" />
                <SHPlayer
                  videoType="vod"
                  ref="mainVideo"
                  class="main-video"
                  playerType="tcPlayer"
                  :fans_id="fans_id"
                  @play="onPlayerPlay"
                  @pause="onPlayerPause"
                  @playSpeed="handlePlaySpeed"
                  @seeking="handleSeek"
                />
                <div style="height: 90px; width: 100%">
                    <div>
                        <img class="fanhui" @click="goOff()" style="margin-top: 5px"
                            src="../assets/images/fanhuizhong.png" />
                    </div>
                </div>
            </div>
            <div class="diyi" v-if="isThreeScreenCourse">
                <SHPlayer
                  videoType="vod"
                  ref="whiteBoard"
                  class="main-video"
                  :speedValue="whiteBoardSpeed"
                  :hideControl="true"
                  playerType="tcPlayer"
                  :fans_id="fans_id"
                />
            </div>

            <div class="debf">
                <p class="fafa">{{ name }}</p>
                <div>
                    <div class="zhibosj">
                        直播时间：{{ start_timeList.start_time }}
                    </div>
                </div>
            </div>
        </div>
        <div class="contentBox" :style="{paddingTop: isThreeScreenCourse ? '12.38rem' : '7.38rem'}">
            <div class="lineBox"></div>
            <div class="dsanbf">
                <div style="margin-top: 0.43rem; padding: 0 0.43rem">
                        <div class="zhangjielist"> 
                            <div style="margin-top: 0.43rem" v-for="(item1, index) in OneChapterList" :key="item1.id">
                                    <div style="width: 235px;">
                                        <span class="linyi">{{index > 8 ? index + 1 : "0" + (index + 1)}}</span>
                                        <span v-if="item1.type === 5" class="huifangb">直播</span>
                                        <!-- <span v-else-if="item1.type === 5 && item1.status !== 3" class="huifangb">直播</span> -->
                                        <span v-if="item1.status !== 3" class="chushia">{{item1.name}}</span>
                                        <span v-else-if="item1.status === 3" class="chushialist">{{item1.name}}</span>
                                    </div>
                                    <div style="margin-top: 4px">
                                        <span class="shijiand">{{ item1.start_time }}</span>
                                        <span v-if="item1.status === 4 || item1.status === 2" @click="liveAuth(item1)"
                                            class="jirzb">进入直播</span>
                                        <span v-if="item1.status === 1" class="jirzblist">直播未开始</span>
                                        <span v-if="item1.status === 3" class="jirzblist">直播已结束</span>
                                    </div>
                                    <div @click="liveAutha(item1, item2.id)" v-for="item2 in item1.videos"
                                        :key="item2.id">
                                        <div class="videoItem">
                                            <img class="playBtn" src="../assets/images/bofang.png" />
                                            <span :class="acterss === item2.id ? 'active' : ''" class="chushia">{{ item2.name
                                            }}</span>
                                            <p class="time">{{ transformation(item2.duration) }}</p>
                                        </div>
                                    </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ActionSheet, Toast, Popup } from "vant";
import { mapGetters } from "vuex";
import Vue from "vue";
import SHPlayer from 'shplayer';
import { getPrefixHost } from '@/utils/index'
// import { ref } from "vue";
Vue.use(Popup);
Vue.use(Toast);
Vue.use(ActionSheet);
export default {
    name: "liveBroadcast",
    computed: {
        ...mapGetters({
            org_id: "user/getOrgId",
            fans_id : "user/getFansId",
        }),
    },
    data() {
        return {
            OneChapterList:'',
            acterss: null,
            name: "",
            desc: "",
            options: [],
            value: "",
            optionsa: [],
            optionsaAll: [],
            class_id: 0,
            showAll: false,
            optionsaAlla: [],
            start_timeList: "",
            studyNum: 0,
            numStatus: "",
            show: false,
            showClass: false,
            currClassName: "",
            cover: "",
            VODData: null,
            // activeNames: [],
            playerType: "vod",
            currentPlayerData: null,
            vodTime: null,
            playStatus: '',
            vodDom: null,
            fans_id: '',
            uid: '',
            fileId: '',
            wx_h5_url: "https://u.shanhuclass.cn",
            currentSectionId: '',
            currentVideoId: '',
            sectionVideoList: [],
            isThreeScreenCourse: false,
            whiteBoardSpeed: 1,
        };
    },
   async created() {
        console.log(this.$route.query.setid);
        this.getCourseInfo();
        await this.getUserInfo()
        this.get1v1List()
        this.getPlayerH5URL()
    },
    mounted(){
    this.fans_id = this.$store.state.user.fans_id 
    this.uid = this.$store.state.user.org_id
  },
    beforeDestroy() {
        clearInterval(this.vodTime)
    },
    components: {
        SHPlayer,
    },
    methods: {
        async getPlayerH5URL(){
            try {
                let data =  await this.$api.get('https://shkt-online-live-1317978474.cos.accelerate.myqcloud.com/web/config/miniConfig.json?a='+ new Date().valueOf())
                if (data && data.data && data.data.wx_h5_url) {
                    this.wx_h5_url = data.data.wx_h5_url;
                }
                if (data && data.data && data.data.wx_h5_url) {
                    this.wx_h5_url = `https://${getPrefixHost(this.org_id)}.${data.data.wx_h5_main_host}`
                }
            } catch (error) {
                console.error(error)
            } 
            
            try {
                let data2 =  await this.$api.get('https://tengfaceliveuser.tengface.net/web/config/userConfig/'+ this.org_id +'.json?a='+ new Date().valueOf())
                if (data2 && data2.data && data2.data.wx_h5_url) {
                    this.wx_h5_url = data2.data.wx_h5_url;
                }
            } catch (error) {
                console.error(error)
            }
       },
        //1v1章节列表
        get1v1List() {
            const data = {
                course_id: this.$route.query.setid,
            }
            this.$api.post("stu/course/list1v1Chapters", data)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.OneChapterList = res.data.data.list
                        console.log(this.OneChapterList, 'asdasdasdasdasdasdasda')
                    }
                })
        },
        guanbiclassget() {
            this.showClass = false;
        },
        goOff() {
            this.$router.go(-1);
        },
        async getUserInfo() {
            const res = await this.$api.post('/user/index/getUserInfo')
            if (res.data.code === 200) {
                this.fans_id = res.data.data.id
                this.uid = res.data.data.org_id
            }
        },
        getCourseInfo() {
            // 课程详情
            const data = {
                org_id: this.org_id,
                course_id: this.$route.query.setid,
            }
            this.$api.post("/stu/course/getCourseInfo", data).then((res) => {
                if (res.data.code === 200) {
                    this.name = res.data.data.name;
                    this.desc = res.data.data.desc;
                    this.cover = res.data.data.cover;
                }
            })
        },
        liveAuth(item1) {
            // 直播鉴权
            console.log('111', item1)
            const data = {
                course_id: this.$route.query.setid,
                class_id: this.class_id,
                chapter_id: item1.id,
            };
            this.$api.post("/stu/course/liveAuth", data).then((res) => {
                if (res.data.code === 200) {
                    if (item1.type == 4) {
                        this.fakeLiveJump(item1, res.data)
                    } else {
                        this.liveAddress(item1, res.data);
                    }
                } else {
                    Toast.fail(res.data.message);
                }
            });
        },

        transformation(duration) {
            let g = duration;
            let m = parseInt((duration / 60) % 60);
            let s = duration % 60;
            if (s <= 9) {
                s = "0" + s;
            }
            if (m <= 9) {
                m = "0" + m;
            }
            if (g >= 3600) {
                g = g / 3600;
                g = parseInt(g);
                g = "0" + g + ":";
            } else {
                g = "";
            }
            return g + m + ":" + s;
        },
        resetData() {
            this.isThreeScreenCourse = false
        },
        liveAutha( item, video_id) {
            this.acterss = video_id
            this.resetData()
            // this.currentSectionId = sectionId;
            this.currentVideoId = video_id

            // 录播鉴权
            // console.log('00000000000sectionId===', sectionId, item)
            const data = {
                course_id: this.$route.query.setid,
                class_id: this.class_id,
                chapter_id: item.chapter_id || item.id,
                video_id: video_id,
            };
            this.$api.post("/stu/course/videoAuth", data).then((res) => {
                if (res.data.code === 200) {
                    sessionStorage.setItem('shipingID', video_id)
                    const vodData = {
                        mp4Url: res.data.data.video.mp4Url,
                    };
                    this.fileId = res.data.data.video.file_id
                    this.VODData = vodData;
                   
                    this.handlePlayVideo(
                        res.data.data.video,
                        res.data.data.new_video,
                        res.data.data.new_video_wb
                    );
                    this.startVodTime()
                } else {
                    Toast.fail(res.data.message);
                }
            });
        },
         // eslint-disable-next-line no-unused-vars
        handlePlayVideo(item, new_video, new_video_w){
            // eslint-disable-next-line no-unused-vars
            const { mp4Url,sMp4Url,file_id,appid,sign,s_appid,s_file_id,s_sign,original,s_original } = item;

            console.log("handlePlayVideo====", item);
            let options = {}
            let sOptions = {}

            if (original === 1) { //播URL
                options = {
                sources: [mp4Url],
                playType: 'url',
                // startTime: this.memoryPoints,
                autoplay: true,
                };
            }else { //播filedid
                options = {
                    fileID: file_id,
                    appID: appid,
                    psign: sign,
                    autoplay: true,
                    playType: 'file_id',
                    // startTime: this.memoryPoints,
                };
            }

            if (s_file_id || sMp4Url) { //有白板视频
                this.isThreeScreenCourse = true
                if (s_file_id) {
                    sOptions = {
                        fileID: s_file_id,
                        appID: s_appid,
                        psign: s_sign,
                        playType: 'file_id',
                        sources: [sMp4Url],
                        // startTime: this.memoryPoints,
                        autoplay: true,
                    }
                }else if (sMp4Url) {
                    sOptions = {
                        sources: [sMp4Url],
                        playType: 'url',
                        // startTime: this.memoryPoints,
                        autoplay: true,
                    }
                }
            }
            
            this.$nextTick(() => {
                this.$refs.mainVideo.setPlayerParams(options);
                if (this.isThreeScreenCourse) {
                    this.$refs.whiteBoard.setPlayerParams(sOptions);
                }
            });

            },
        startVodTime() {
            clearInterval(this.vodTime)
            this.vodTime = setInterval(() => {
                let token = localStorage.getItem('token')
                if (!token) {
                this.vodTime && clearInterval(this.vodTime)
                }
                if (this.playStatus == 2 && token) {
                    const progress = parseInt(this.vodDom.currentTime)
                    const data = {
                        file_id: this.fileId,
                        fans_id: this.fans_id,
                        uid: this.uid,
                        progress
                    }
                    this.$dataApi.post("/dataapi/app/index/videoHeart", data).then(res => {
                        console.log(res)
                    })
                }
            }, 5000)
        },
        fakeLiveJump(item1, item) {
            const liveUrl = `${this.wx_h5_url}/f/?ds_id=${item1.ds_id}&tid=${item1.room_id
                }&token=${item.data.liveToken
                }&other=1&shool_token=${localStorage.getItem('token')}`

            console.log("fakeLiveJump", liveUrl);

            location.href = liveUrl
        },
        liveAddress(item1, item) {
            console.log("item1", item1)
            // 查询直播地址
            if(item1.play_style == 4){
                location.href = `${this.wx_h5_url}/tcicMiddlePage/#/h5?ds_id=${item1.ds_id}&?classid=${item1.interact_id}&fans_id=${this.fans_id}`
            }else{
                const liveUrl = `${this.wx_h5_url}/?ds_id=${item1.ds_id
                }&token=${item.data.liveToken
                }&other=1&shool_token=${localStorage.getItem('token')}`
                console.log("liveAddress", liveUrl);
                location.href = liveUrl;
            }
            
        },
        onPlayerPlay() {
            this.playStatus = 2
            if(this.isThreeScreenCourse) {
                this.$refs.whiteBoard.play();
                // this.$refs.cameraVideo.play();
                return;
            }
        },
        onPlayerPause() {
            this.playStatus = 3
            if(this.isThreeScreenCourse) {
                this.$refs.whiteBoard.pause()
                // this.$refs.cameraVideo.play();
                return;
            }
        },
        handleSeek(val){
            if(this.isThreeScreenCourse) {
                this.$refs.whiteBoard.setCurrentTime(val, true);
            } 
        },
        handlePlaySpeed(val){
            if(this.isThreeScreenCourse) {
                this.whiteBoardSpeed = val;
            }
        },
    }
}
</script>
<style scoped>
.tfPlayer>>>.playBtn {
    width: 12% !important;
}

.contentBox>>>.van-cell--clickable {
    background: #f6f6f6;
}

.liveBroadcast .headerBox {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
}

.liveBroadcast .contentBox {
    padding-top: 7.38rem;
}

.dsanbf>>>.el-input__inner {
    border: none;
    width: 185px;
}

.banji {
    font-size: 0.32rem;
    line-height: 0.43rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #888888;
    float: right;
    margin-top: 0.48rem;
    margin-right: 0.43rem;
}

/* 头部 */
.diyi {
    width: 100%;
    height: 5.33rem;
    overflow: hidden;
    background: #fff;
}

.diyi .courseCover {
    z-index: 3000;
    width: 100%;
    height: 100%;
}

.fanhui {
    width: 25px;
    height: 25px;
    opacity: 0.5;
    background: #000000;
    border-radius: 50%;
    text-align: center;
    top: 10px;
    left: 10px;
    position: absolute;
    z-index: 9999;
}

.fanhuiyi {
    width: 30px;
    height: 30px;
    opacity: 0.5;
    background: #000000;
    border-radius: 50%;
    text-align: center;
    margin-top: 13px;
}

.fanhuier {
    width: 30px;
    height: 30px;
    opacity: 0.5;
    background: #000000;
    border-radius: 50%;
    text-align: center;
    margin-top: 13px;
    margin-left: 18px;
}

.mianfei {
    width: 109px;
    height: 40px;
    opacity: 0.5;
    background: #000000;
    border-radius: 20px;
    clear: both;
    margin: 0 auto;
    /* margin-top: 88px; */
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
}

/* 第二部分 */
.debf {
    width: 100%;
    height: 77px;
    background: #fff;
    overflow: hidden;
}

.liveBroadcast .fafa {
    font-size: 0.48rem;
    line-height: 0.64rem;
    font-weight: 600;
    color: #101010;
    margin-top: 0.27rem;
    margin-left: 0.43rem;
    margin-bottom: 0px;
}

.liveBroadcast .zhibosj {
    margin: 0px;
    margin-top: 0.29rem;
    margin-left: 0.43rem;
    font-size: 0.32rem;
    line-height: 0.43rem;
    font-weight: 400;
    color: #888888;
}

.liveBroadcast .rightStudy {
    float: right;
    margin-right: 0.43rem;
}

.liveBroadcast .rightStudy img {
    vertical-align: -0.05rem;
}

.liveBroadcast .qishi {
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #10a675;
    margin-left: 0.13rem;
}

.liveBroadcast .lineBox {
    width: 100%;
    height: 0.27rem;
    background-color: #f6f6f6;
}

/* 第三部分 */
.liveBroadcast .dsanbf {
    width: 100%;
    height: 100%;
    background: #ffffff;
    overflow: hidden;
    padding-bottom: 0.53rem;
}

.liveBroadcast .mulu {
    font-size: 0.48rem;
    line-height: 0.59rem;
    font-weight: 600;
    color: #101010;
    margin-top: 0.43rem;
    margin-left: 0.43rem;
    margin-bottom: 0px;
}

.liveBroadcast .courseItemView {
    overflow: hidden;
    margin-top: 0.53rem;
}

.liveBroadcast .linyi {
    font-size: 0.37rem;
    color: #20242f;
    margin-left: -0.27rem;
}

.liveBroadcast .videoItem {
    margin-left: 0.63rem;
    margin-top: 0.43rem;
}

.liveBroadcast .videoItem .playBtn {
    width: 0.37rem;
    vertical-align: -0.05rem;
}

.liveBroadcast .videoItem .time {
    float: right;
    font-size: 0.37rem;
    color: #888888;
    margin-right: 0.53rem;
    margin-top: 0.1rem;
    margin-bottom: 0px;
}

.huifanga {
    background: #d9e8fe;
    border-radius: 2px;
    padding: 2px 3px;
    font-size: 0.32rem;
    font-weight: 400;
    color: #3f8ef8;
    margin-left: 0.45rem;
}

.huifangb {
    background: #e6f6f0;
    border-radius: 2px;
    padding: 2px 3px;
    font-size: 0.32rem;
    font-weight: 400;
    color: #10a675;
    margin-left: 0.45rem;
}

.chushi {
    font-size: 0.37rem;
    font-weight: 500;
    color: #10a675;
    margin-left: 6px;
}

.chushia {
    font-size: 0.37rem;
    font-weight: 500;
    color: #101010;
    margin-left: 0.21rem;
}

.chushialist {
    font-size: 0.37rem;
    font-weight: 500;
    color: #101010;
    margin-left: 0.43rem;
}

.shijianb {
    font-size: 0.32rem;
    font-weight: 400;
    color: #10a675;
    margin-left: 49px;
}

.shijianc {
    font-size: 0.32rem;
    font-weight: 400;
    color: #10a675;
    margin-left: 10px;
}

.liveBroadcast .shijiand {
    font-size: 0.32rem;
    font-weight: 400;
    color: #888888;
    margin-left: 1.28rem;
}

.shijianf {
    font-size: 0.32rem;
    font-weight: 400;
    color: #888888;
    margin-left: 10px;
}

.gengduo {
    font-size: 0.37rem;
    font-weight: 400;
    color: #10a675;
}

/* 第四部分 */
.dsibf {
    width: 100%;
    background: #fff;
    overflow: hidden;
}

.meiyou {
    width: 100%;
    height: 37px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #888888;
}

.dibu {
    width: 100%;
    height: 58px;
    background: #fefffe;
    box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.05);
}

.liji {
    width: 260px;
    height: 40px;
    background: linear-gradient(90deg, #fdb56b, #fc6d2b);
    border-radius: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 9px;
    margin-left: 31px;
}

.active {
    color: #10a675;
}

.xuanban {
    font-size: 0.37rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #000000;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 33px;
}

.luse {
    font-size: 0.43rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    /* color: #10a675; */
    margin-left: 20px;
    margin-top: 26px;
}

.xuanzhong {
    float: right;
    margin-top: -36px;
    margin-right: 25px;
}

.xuanzhong img {
    width: 0.53rem;
    height: 0.53rem;
}

.jirzb {
    margin-right: 0.68rem;
    float: right;
    background: linear-gradient(95deg, #78d4b6 4%, #0bb67e 95%);
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #fefffe;
    padding: 5px 11px;
    border-radius: 14px;
    margin-top: -20px;
}

.jirzblist {
    margin-right: 0.6rem;
    float: right;
    font-size: 0.32rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #888888;
    margin-top: -20px;
}

.guanbiclass {
    position: absolute;
    width: 0.37rem;
    height: 0.37rem;
    top: 0.61rem;
    right: 0.4rem;
}

.zhangjielist {
    font-size: 0.43rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #20242f;
    margin-top: 0.27rem;
}
</style>
  
<style>
.kecheng_desc img {
    width: 100%;
}
</style>
  