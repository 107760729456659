import GLOBAL_CONFIG from '../../config/index'


export default {
    SHANHU_LIVE_API: GLOBAL_CONFIG.apis.SHANHU_LIVE_API,
    SHANHU_DATA_API: GLOBAL_CONFIG.apis.SHANHU_DATA_API,
    SHANHU_ONLINE_API: GLOBAL_CONFIG.apis.SHANHU_ONLINE_API,
    SHANHU_LIVE_HY_API: GLOBAL_CONFIG.apis.SHANHU_LIVE_HY_API

    // SHANHU_LIVE_API: 'https://live.shkt.online',
    // SHANHU_DATA_API: 'https://data.shkt.online',
    // SHANHU_ONLINE_API: 'https://school.shkt.online',
    // SHANHU_LIVE_HY_API: 'https://livehy.shkt.online'

    // SHANHU_LIVE_API: 'https://preview_live.shkt.online',
    // SHANHU_DATA_API: 'https://preview_data.shkt.online',
    // SHANHU_ONLINE_API: 'https://preview_online.shkt.online',
    // SHANHU_LIVE_HY_API: 'https://prehy_live.shkt.online',
}
